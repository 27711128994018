import React, { useState } from "react";
import styled from "styled-components";
import {Link} from "react-router-dom"

const Navbar = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  return (
    <NavbarContainer>
      {/* Logo */}
      <Logo>
        <img src="logo.png" alt="Logo" />
      </Logo>

      {/* Links */}
      <MenuContainer isOpen={isOpen}>
        <StyledLink to='/'>Home</StyledLink>
        <StyledLink to='/leads'>Leads</StyledLink>
        <StyledLink to='/accounts'>Accounts</StyledLink>
        <StyledLink to='/contacts'>Contacts</StyledLink>
        <StyledLink to='/deals'>Deals</StyledLink>
        <StyledLink to='/projects'>Projects</StyledLink>
        <StyledLink to='/vendor'>Vendor</StyledLink>
        <StyledLink to='/partner'>Partner</StyledLink>
        <StyledLink to='/campaigns'>Campaigns</StyledLink>
      </MenuContainer>

      {/* Hamburger Toggle for Mobile */}
      <HamburgerIcon onClick={toggleMenu}>
      <img src="https://upload.wikimedia.org/wikipedia/commons/b/b2/Hamburger_icon.svg" alt="Menu" />
      </HamburgerIcon>
    </NavbarContainer>
  );
};

export default Navbar;


// Styling for the Navbar container using Flexbox
const NavbarContainer = styled.nav`
  position: fixed;
  top: 0;
  width: 100%;
  background-color: #333;
  color: white;
  padding: 20px 20px; /* Padding on top/bottom and left/right */
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-sizing: border-box; /* Ensures padding is included in the width calculation */
  z-index: 1000;
`;

// Logo styling
const Logo = styled.div`
  img {
    height: 40px;
  }
`;

// Links styling
const MenuContainer = styled.div`
  display: flex;
  gap: 20px;

  @media (max-width: 768px) {
    display: none;
    flex-direction: column;
    position: absolute;
    top: 60px;
    right: 20px; /* Adjusted to ensure padding is respected */
    background-color: #333;
    padding: 10px;
    width: calc(100% - 40px); /* Adjusted for padding */
    box-sizing: border-box; /* Ensures padding is included in the width calculation */
  }

  ${({ isOpen }) =>
    isOpen &&
    `
    @media (max-width: 768px) {
      display: flex;
    }
  `}
`;

// Individual link styling
const StyledLink = styled(Link)`
  padding: 5px 0;
  color: white;
  text-align: center;
  text-decoration: none;
  font-size: 18px;

  &:hover {
    color: #D8A25E;
  }
`;

const Menu = styled.div`
  padding: 15px 0;
  color: white;
  text-align: center;
  text-decoration: none;
  font-size: 18px;

  &:hover {
    background-color: #555;
  }
`;

// Hamburger icon styling
const HamburgerIcon = styled.div`
  display: none;
  cursor: pointer;

  @media (max-width: 768px) {
    display: block;
  }

  img {
    height: 30px;
    width: 30px;
  }
`;


// import React, { useState } from "react";
// import 'bootstrap/dist/css/bootstrap.min.css';
// import { Navbar, Nav, Container } from 'react-bootstrap';

// const MyNavbar = () => {
//   const [expanded, setExpanded] = useState(false);

//   return (
//     <Navbar 
//       bg="dark" 
//       variant="dark" 
//       expand="lg" 
//       fixed="top" 
//       expanded={expanded}
//     >
//       <Container>
//         {/* Logo */}
//         <Navbar.Brand href="#home">
//           <img 
//             src="logo.png" 
//             alt="Logo" 
//             style={{ height: '40px' }} 
//           />
//         </Navbar.Brand>

//         {/* Hamburger Menu */}
//         <Navbar.Toggle 
//           aria-controls="basic-navbar-nav" 
//           onClick={() => setExpanded(expanded ? false : "expanded")}
//         />

//         {/* Navbar Links */}
//         <Navbar.Collapse id="basic-navbar-nav" style={{flexDirection:"row-reverse"}}>
//           <Nav className="ml-auto">
//             <Nav.Link href="#home">Home</Nav.Link>
//             <Nav.Link href="#contact">Contact</Nav.Link>
//             <Nav.Link href="#leads">Leads</Nav.Link>
//           </Nav>
//         </Navbar.Collapse>
//       </Container>
//     </Navbar>
//   );
// };

// export default MyNavbar;
