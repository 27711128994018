import './App.css';
import Accounts from './components/Accounts';
import Contacts from './components/Contacts';
import Deals from './components/Deals';
import Home from './components/Home';
import Leads from './components/Leads';
import NavBarComp from './components/NavBarComp';
import {BrowserRouter,Routes,Route,Router} from "react-router-dom"
import styled from "styled-components";
import Projects from './components/Projects';
import Vendor from './components/Vendor';
import Partner from './components/Partner';
import Campaigns from './components/Campaigns';

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <NavBarComp/>
        <Content>
          <Routes>
            <Route path='/' element={<Home/>}/>
            <Route path='/leads' element={<Leads/>}/>
            <Route path='/accounts' element={<Accounts/>}/>
            <Route path='/contacts' element={<Contacts/>}/>
            <Route path='/deals' element={<Deals/>}/>
            <Route path='/projects' element={<Projects/>}/>
            <Route path='/vendor' element={<Vendor/>}/>
            <Route path='/partner'element={<Partner/>}/>
            <Route path='/campaigns' element={<Campaigns/>}/>
          </Routes>
        </Content>
      </BrowserRouter>
    </div>
  );
}

export default App;


const Content = styled.div`
  padding-top: 60px; /* Adjust this value based on the height of your fixed navbar */
`;
